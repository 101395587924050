import React from "react";
import styles from "./styles.module.scss";
import Image from "next/legacy/image";
import { customSizes } from "../../utils/imgUtils";
import cloudflareLoader from "../../Scripts/cloudflareCustomImageLoader";

export default function QuickLinks({
  link,
  text,
  icon,
  navigateTo,
  image,
}: any) {
  return (
    <div>
      <div
        className={styles.quickLinksContain}
        onClick={() => navigateTo(link)}
      >
        {image ? (
          <div className={styles.quickLinkImage}>
            <Image
              loader={cloudflareLoader}
              src={icon}
              layout="fill"
              objectFit="contain"
              alt="image"
              //
              sizes={customSizes()}
            />
          </div>
        ) : (
          <span className={styles.quickLinksIcon}>
            <svg>
              <use href={icon} />
            </svg>
          </span>
        )}
        <p>{text}</p>
      </div>
    </div>
  );
}
