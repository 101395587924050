import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import CarouselComponent from "../../components/carousel";
import QuickLinks from "../../components/quickLinks";
import { connect } from "react-redux";
import { homeSelectors } from "../../state/home";
import { useRouter } from "next/router";
import { isEmpty } from "lodash";
import { authActions, authSelectors } from "../../state/auth";
import PopUp from "../../components/pop-up";
import Image from "next/legacy/image";
import { bucketURLBuilder } from "../../utils/bucket";
import { bannerSizes } from "../../utils/imgUtils";
import { useClient } from "../../hooks/useClientState";
import cloudflareLoader from "../../Scripts/cloudflareCustomImageLoader";


const Homepage = (props: any) => {
  const { firstTimeSignupFlag, selectedStore } = props;
  const router = useRouter();
  const [userIsLogin, setUserIsLogin] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [alert, setAlert] = useState(false);
  const cdata = useClient()

  const styleJSON = ((cdata.data["clientMeta"] || {})["styleJson"] || {})
  const storeName = styleJSON.storeName  || ""
   


  const bannerData = (props["data"] || []).sort((x: any, y: any) => x["order"] - y["order"])
  const secondaryBanner = bannerData.find((item: any) => item["imageType"] === "home_secondary")
  const primaryBanners = bannerData.filter((item: any) => item["imageType"] === "home_primary")


  useEffect(() => {
    if (firstTimeSignupFlag) {
      setPopupContent("Your profile has been successfully created");
      setAlert(true);
    }
  }, [firstTimeSignupFlag]);

  useEffect(() => {
    const { loginUser } = props;
    if (!isEmpty(loginUser)) {
      setUserIsLogin(true);
    } else {
      setUserIsLogin(false);
    }
  }, []);

  const navigateTo = () => {
    const { firstTimeSignUp } = props;
    firstTimeSignUp({ firstTimeSignup: false });
    const modifiedStoreName = selectedStore?.name
      ?.toLowerCase()
      .replace(/\s+/g, "-");
    if (!isEmpty(modifiedStoreName)) {
      router.push(`/menu/${modifiedStoreName}/${selectedStore.uuid}`);
    } else {
      router.push("/store-listing");
    }
  };

  const closePopup = () => {
    setAlert(false);
    const { firstTimeSignUp } = props;
    firstTimeSignUp({ firstTimeSignup: false });
  };

  return (
    <>
      <PopUp
        title={`Welcome to the ${storeName} family!`}
        popup_display={alert}
        content={popupContent}
        closePopup={() => {
          closePopup();
        }}
      />

      <div className={styles.homepage}>
        <div className={styles.homeflex}>
          <CarouselComponent
            data={primaryBanners}

          />

          <div className={styles.quickLinksSection}>
            {(styleJSON["quickLinks"] || [] ).map((elem: any, i: any) => {
              return (
                <QuickLinks
                  key={i}
                  link={elem.link}
                  text={elem.text}
                  image={true}
                  icon={elem.image}
                  navigateTo={() => {
                    navigateTo();
                  }}
                />
              );
            })}
          </div>
        </div>

        <div style={{  position: "relative", margin: "auto", padding: "1rem" }}>

          { secondaryBanner && (<>
            <Image
              loader={cloudflareLoader}
              src={bucketURLBuilder(secondaryBanner["desktopFilePath"])}
              layout="fill"
              objectFit="contain"
              alt={secondaryBanner["altText"] || ""}
              sizes={bannerSizes()}
              priority={false}
              onClick={() => {
                const link = secondaryBanner["link"]
                if (link) {
                  window.open(link)
                }
              }}
            />

          </>)}


        </div>        
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  firstTimeSignUp: (params: any) =>
    dispatch(authActions.firstTimeSignUp(params)),
});

const mapStateToProps = (state: any) => ({
  selectedStore: homeSelectors.getStore(state),
  loginUser: authSelectors.getUser(state),
  firstTimeSignupFlag: authSelectors.getFirstTimeSignUpFlag(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
