import { Carousel } from "react-responsive-carousel";
import styles from "./styles.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Image from "next/legacy/image";
import { bannerSizes } from "../../utils/imgUtils";
import { bucketURLBuilder } from "../../utils/bucket";
import cloudflareLoader from "../../Scripts/cloudflareCustomImageLoader";

interface PropTypes {
  data: object[]
}

const CarouselComponent = (props: PropTypes) => {

  return (
    <div className={styles.carouselContain}>
      <Carousel
        showStatus={false}
        infiniteLoop={true}
        showThumbs={false}
        showArrows={false}
        autoPlay={true}
        interval={5000}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          return (
            <span
              key={index}
              className={isSelected ? styles.activeDot : styles.idleDot}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {props.data?.map((item: any, index: number) => {
          return (
            <div className={styles.carouselItem} key={item["id"]}>
              <Image
                loader={cloudflareLoader}
                src={bucketURLBuilder(item["desktopFilePath"])}
                layout="fill"
                objectFit="contain"
                alt={item["altText"] || ""}
                sizes={bannerSizes()}
                priority={index < 1}
                onClick={(ev) => {
                  const link = item["link"]
                  if (item["link"]) {
                    window.open(link)                    
                  }
                }}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
